import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import post from './apiService'

function checkToken() {
	
	return true; 
	
	/*
    const token = localStorage.getItem('token')
    if (token) {
        return true
    } else {
        return false
    }
	*/
}



function checkClientToken() {
	
	return true; 
	
	/*
    const token = localStorage.getItem('clienttoken')
    if (token) {
        return true
    } else {
        return false
    }
	*/
}


export function clusterFilter (clusterData, clusterId) {
    if (clusterData && clusterData.length > 0) {
        for (let index = 0; index < clusterData.length; index++) {
            const cluster = clusterData[index];
            if (cluster.clusterid === parseInt(clusterId)) {
                return cluster
            }
        }
    }
}


export function handleSelectData(name, value, setStateFunction) {
    setStateFunction(value);
}


export function formatDate(date, time=false) {
    if (date) {
        date = new Date(date);
        const day = date.getDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
        const year = date.getFullYear().toString();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
    
        let formattedDate = `${day}${month}${year}`;
        const formattedTime = `${hours}:${minutes}:${seconds}`;

        if (time) {
            formattedDate = formattedDate + ' ' + formattedTime
        }
        return formattedDate;
    }
    return date;
}



export async function getMerchantList(user_role = null)
{
	if (user_role == null)
		user_role = localStorage.getItem('user_roles');
	
	let responseData = {}; 
	
	console.log(user_role ); 
	
	console.log(responseData) ; 
	
	if (user_role === "sa")
	{
		responseData = await post('merchant/', {});
	}
	else 
	{
		var merchantId = localStorage.getItem('last_merchant_id');
		responseData = await post('merchant/', {"merchant_id": merchantId});
	}
	
	console.log(responseData) ; 
	
	// if (responseData.merchants != null)
	// 	localStorage.setItem('merchant_list', responseData.merchants);
	
	
	
}



export function templateSelect (dataList, selectDivId, dataKey, addAllOption=false) {
    if (dataList) {
        var selectDiv = document.getElementById(selectDivId);
        var ulElement = selectDiv.querySelector('ul');
        var html = "";
        var n = 0;
        if(addAllOption === true) {
            html += `
            <li data-original-index="${n}">
                <a tabindex="0" class="" data-tokens="null" role="option" aria-disabled="false" aria-selected="false">
                    <span class="text">All</span>
                    <span class="glyphicon glyphicon-ok check-mark"></span>
                </a>
            </li>`;
        }
        for (const key in dataList) {
            if (dataList.hasOwnProperty(key)) {
                const data = dataList[key];
                n += 1;
                html += `
                <li data-original-index="${n}">
                    <a tabindex="0" class="" data-tokens="null" role="option" aria-disabled="false" aria-selected="false">
                        <span class="text">${data[dataKey]}</span>
                        <span class="glyphicon glyphicon-ok check-mark"></span>
                    </a>
                </li>`;
            }
        }
        if (ulElement) {
            ulElement.innerHTML = html;
        }
    }
}

export function getRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function excelDownload (excelData, fileName, sheetName='Sheet1') {

    // Create a new Excel workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Generate a binary string from the workbook
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Convert the buffer to a Blob and trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName + '.xlsx');
};


export function calculatePercentage (total, used) {
    if (total === 0) {
        return 0; // To avoid division by zero error
    }
    const free = total - used;
    const freePercentage = (free / total) * 100;
    return freePercentage.toFixed(2); // Adjust the decimal places as needed
}


export default checkToken;