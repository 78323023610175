// const BASE_URL = "http://54.151.215.138:8080/";
// const BASE_URL = "https://iaas-ha-api-nexsoft2-ocpcloud-ccssp-dev.devcld73.server.ha.org.hk/";

// const BASE_URL = "http://52.221.127.231:8080/";
const BASE_URL = "https://api.ientry.io/";

const API = "api/";
const IMAGES_PREFIX = "images/";

const WEB_STATE = "Development";
// const WEB_STATE = "Production";

const HEALTHCHECK_INDEX = "DC7";

const LOGIN_NAME = "Corp\\";

export { BASE_URL, API, WEB_STATE, LOGIN_NAME, HEALTHCHECK_INDEX, IMAGES_PREFIX };