import { BASE_URL, API } from '../config';

const token = localStorage.getItem('token') || '';
const userId = localStorage.getItem('user_id') || '';

const post = async (url, data={}) => {
    data.token = token;
    data.user_id = userId;

    try {
        const response = await fetch( BASE_URL + API + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data),
        });
        if (response.ok) {
            const responseData = await response.json();
            if (responseData.status === false && responseData.message === 'Token invalid or expired'){
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                window.location.href = '/login?isExpired=true';
            } else {
            return responseData
            }
        } else {
            console.error('Error:', response);
        }
    } catch (error) {
        console.error('Error:', error);
        if (error.message === "Failed to fetch") {
            window.location.href = "/500"
        }
    }
};



export default post;
