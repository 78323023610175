import React from "react";


const Loader = () => {

    return (
      <div className="page-loader-wrapper bg-white">
        <div className="loader">
          <div className="m-t-30">
              <img
              className="zmdi-hc-spin"
              src="/assets/images/loading.png"
              width="40"
              height="40"
              alt="IaaS"
              ></img>
          </div>
          <p className="col-black" id="loading-message">
              Please wait...
          </p>
        </div>
      </div>
    );
  };

export default Loader;